import { useCallback, useEffect, useState } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";

import { BsChevronDoubleUp } from "react-icons/bs";
import { IoLogoInstagram, IoLogoYoutube, IoMdExit } from "react-icons/io";
import { CgMenuRight } from "react-icons/cg";

import { MenuDrawer } from "components/MenuDrawer";
import AppPopover from "../popovers/AppPopover";
import Logo from "assets/logo.png";

import { Container } from "./styles";

export const AuthLayout = (): JSX.Element => {
  const location = useLocation();

  const [openMenu, setOpenMenu] = useState({
    logout: false,
  });

  const handleScroll = useCallback(() => {
    let header = document.querySelector(".header");

    if (window.scrollY > 10) {
      header?.classList.add("sticky");
      return;
    }

    header?.classList.remove("sticky");
  }, []);

  function handleToggleMenu(obj: "logout") {
    setOpenMenu((prevState) => {
      return { ...prevState, [obj]: !prevState[obj] };
    });
  }

  const handleScrollTop = useCallback(() => {
    let top = document.querySelector("#top");

    top?.scrollIntoView({
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.scrollTo(0, 0);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <Container id="top">
      <header className="header">
        <div className="container-fluid">
          <div className="header-container">
            <div className="header-container-left">
              <div className="header-brand">
                <Link to="/">
                  <img
                    src={Logo}
                    alt="TTribo.com"
                    width={50}
                    loading={"lazy"}
                  />
                </Link>
              </div>
              <nav className="header-navegation">
                <ul>
                  <li className={location.pathname === "/" ? "active" : ""}>
                    <Link to="/">Inicio</Link>
                  </li>
                  <li
                    className={location.pathname === "/cursos" ? "active" : ""}
                  >
                    <Link to="/cursos">Meus Cursos</Link>
                  </li>
                  {/*<li*/}
                  {/*  className={*/}
                  {/*    location.pathname === "/ttribo-mais" ? "active" : ""*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*  <Link to="/ttribo-mais">TTribo+</Link>*/}
                  {/*</li>*/}
                  <li
                    className={
                      location.pathname === "/parceiros" ? "active" : ""
                    }
                  >
                    <Link to="/parceiros">Parceiros</Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="actions">
              <AppPopover />

              <button
                className="actions-menu"
                onClick={() => handleToggleMenu("logout")}
              >
                <IoMdExit className="desktop" />
                <CgMenuRight className="mobile" />
              </button>
            </div>
          </div>
        </div>
      </header>
      <MenuDrawer
        open={openMenu.logout}
        onClose={() => handleToggleMenu("logout")}
      />
      <Outlet />

      <footer>
        <div className="container-fluid">
          <div className="footer-container">
            <div className="footer-brand">
              <img src={Logo} alt="Trribo.com" width={50} loading="lazy" />

              <p>©TTribo.com Todos os direitos reservados. V1.0.2</p>

              <button className="back-to-top" onClick={() => handleScrollTop()}>
                Voltar para o topo
                <BsChevronDoubleUp />
              </button>
            </div>
            <nav>
              <div className="nav-column">
                <h3>Navegue</h3>

                <ul>
                  <li>
                    <Link to="/">Início</Link>
                  </li>
                  <li>
                    <Link to="/cursos">Cursos</Link>
                  </li>
                  <li>
                    <Link to="/parceiros">Parceiros</Link>
                  </li>
                </ul>
              </div>

              <div className="nav-column">
                <h3>Links úteis</h3>

                <ul>
                  <li>
                    <Link to="/">Perfil</Link>
                  </li>
                  <li>
                    <Link to="/">Assinatura</Link>
                  </li>
                  <li>
                    <Link to="/">Proteja sua privacidade</Link>
                  </li>
                </ul>
              </div>

              <div className="nav-column">
                <h3>Termos e ajuda</h3>

                <ul>
                  <li>
                    <Link to="/">Central de Ajuda</Link>
                  </li>
                  <li>
                    <Link to="/">Termos de uso</Link>
                  </li>
                </ul>
              </div>

              <div className="nav-column">
                <h3>Redes Sociais</h3>

                <ul className="socias">
                  <li>
                    <a
                      href="https://www.instagram.com/ttribo.oficial/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IoLogoInstagram />
                    </a>
                  </li>
                  {/*<li>*/}
                  {/*  <a href="/" target="_blank" rel="noopener noreferrer">*/}
                  {/*    <IoLogoLinkedin />*/}
                  {/*  </a>*/}
                  {/*</li>*/}
                  {/*<li>*/}
                  {/*  <a href="/" target="_blank" rel="noopener noreferrer">*/}
                  {/*    <IoLogoTwitter />*/}
                  {/*  </a>*/}
                  {/*</li>*/}
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UCKb5SHw83ZXjCsJ64otYrGQ"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IoLogoYoutube />
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </footer>
    </Container>
  );
};
